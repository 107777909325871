<template>
	<AppLayout>
		<template #header>
			<PageHeader :show-breadcrumbs="false" :title="'Search ' + plur(route.params.activity_type.toString(), 2)">
				<template #actions>
					<Button color="white" :border="false" @click="createNewActivity = true">
						Create new {{ plur(route.params.activity_type.toString(), 1) }}
						<FontAwesomeIcon :icon="faPlus" class="h-5 w-5 text-black ml-2 -mr-1" aria-hidden="true" />
					</Button>
				</template>
			</PageHeader>
		</template>
		<template #primary>
			<div class="relative z-0 flex h-full overflow-y-auto">
				<div class="xl:order-first xl:flex xl:flex-col shrink-0 h-full w-full">
					<div class="bg-gray-200 z-20">
						<!-- Filters -->
						<div class="">
							<section aria-labelledby="filter-heading">
								<h2 id="filter-heading" class="sr-only">Filters</h2>

								<div class="relative z-10 bg-white border-t border-b border-gray-200 px-4">
									<div class="mx-auto pl-1 pr-4 flex items-center justify-between">
										<div class="flex rounded-md shadow-sm w-2/3">
											<div class="relative flex items-stretch grow focus-within:z-10 h-10">
												<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
													<FontAwesomeIcon :icon="faMagnifyingGlass" class="h-5 w-5 text-gray-400" aria-hidden="true" />
												</div>
												<input
													id="filter"
													class="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-none pl-10 sm:text-sm border-0 placeholder-gray-500 placeholder-opacity-30"
													name="filter"
													placeholder="Enter text filter"
													type="text"
												/>
											</div>
										</div>

										<button class="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden" type="button" @click="open = true">Filter</button>

										<div class="hidden sm:block">
											<div class="flow-root">
												<PopoverGroup class="-mx-4 flex items-center divide-x divide-gray-200">
													<Popover v-for="(section, sectionIdx) in filters" :key="section.name" class="px-3 relative inline-block text-left">
														<PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
															<span>{{ section.name }}</span>
															<span v-if="sectionIdx === 0" class="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">4</span>
															<span v-if="sectionIdx === 1" class="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">1</span>
															<span v-if="sectionIdx === 2" class="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">1</span>
															<FontAwesomeIcon :icon="faChevronDown" class="shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
														</PopoverButton>

														<transition
															enter-active-class="transition ease-out duration-100"
															enter-from-class="transform opacity-0 scale-95"
															enter-to-class="transform opacity-100 scale-100"
															leave-active-class="transition ease-in duration-75"
															leave-from-class="transform opacity-100 scale-100"
															leave-to-class="transform opacity-0 scale-95"
														>
															<PopoverPanel class="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
																<form class="space-y-4 z-20">
																	<div v-for="(option, optionIdx) in section.options" :key="option.value" class="flex items-center z-20">
																		<input
																			:id="`filter-${section.id}-${optionIdx}`"
																			:checked="option.checked"
																			:name="`${section.id}[]`"
																			:value="option.value"
																			class="h-4 w-4 border-gray-300 rounded text-primary-600 focus:ring-primary-500"
																			type="checkbox"
																		/>
																		<label :for="`filter-${section.id}-${optionIdx}`" class="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap">
																			{{ option.label }}
																		</label>
																	</div>
																</form>
															</PopoverPanel>
														</transition>
													</Popover>
												</PopoverGroup>
											</div>
										</div>
									</div>
								</div>

								<!-- Active filters -->
								<div class="bg-gray-100 px-4">
									<div class="mx-auto py-2 pr-4 pl-4 sm:flex sm:items-center">
										<Menu as="div" class="relative inline-block text-left flex-initial">
											<div>
												<MenuButton class="group inline-flex justify-center items-center text-sm font-medium text-gray-700 hover:text-gray-900">
													<h3 class="text-xs font-semibold uppercase tracking-wide text-gray-500">Sort by: {{ currentSortOption.name }}</h3>
													<FontAwesomeIcon :icon="faChevronDown" class="shrink-0 -mr-1 ml-1 -mt-0.5 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
												</MenuButton>
											</div>

											<transition
												enter-active-class="transition ease-out duration-100"
												enter-from-class="transform opacity-0 scale-95"
												enter-to-class="transform opacity-100 scale-100"
												leave-active-class="transition ease-in duration-75"
												leave-from-class="transform opacity-100 scale-100"
												leave-to-class="transform opacity-0 scale-95"
											>
												<MenuItems class="z-10 origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
													<div class="py-1">
														<MenuItem v-for="option in sortOptions" :key="option.name" v-slot="{ active }">
															<a :class="[option.current ? 'font-medium text-gray-900' : 'text-gray-500', active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm']" :href="option.href">
																{{ option.name }}
															</a>
														</MenuItem>
													</div>
												</MenuItems>
											</transition>
										</Menu>

										<div class="flex-auto inline-block relative flex justify-end items-center">
											<div class="mt-2 sm:mt-0 sm:ml-4">
												<div class="-m-1 flex flex-wrap items-center">
													<span v-for="activeFilter in activeFilters" :key="activeFilter.value" class="m-1 inline-flex rounded-full border border-gray-200 items-center py-1 pl-3 pr-2 text-sm font-normal bg-white text-gray-900">
														<span>{{ activeFilter.label }}</span>
														<button class="shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500" type="button">
															<span class="sr-only">Remove filter for {{ activeFilter.label }}</span>
															<svg class="h-2 w-2" fill="none" stroke="currentColor" viewBox="0 0 8 8">
																<path d="M1 1l6 6m0-6L1 7" stroke-linecap="round" stroke-width="1.5" />
															</svg>
														</button>
													</span>
												</div>
											</div>
											<div aria-hidden="true" class="hidden w-px h-5 bg-gray-300 sm:block sm:ml-2" />
											<h3 class="text-xs font-semibold uppercase tracking-wide text-gray-500 ml-3">Active Filters</h3>
										</div>
									</div>
								</div>
							</section>
						</div>
						<div aria-hidden="true" class="inset-0 flex items-center bg-white">
							<div class="w-full border-t border-gray-300" />
						</div>
					</div>

					<div class="px-4 sm:px-6 lg:px-8 flex justify-center h-full w-full mb-1">
						<div v-if="!activityStore.loading" class="flex w-full h-full">
							<div class="w-full">
								<div class="flex-1 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 w-full md:rounded-lg">
									<table class="flex-1 min-w-full divide-y divide-gray-300">
										<thead class="bg-gray-50">
											<tr>
												<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Description</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Type</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Visibility</th>
												<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Last updated by</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Created by</th>
												<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"></th>
											</tr>
										</thead>
										<tbody v-if="activityStore.activities.length > 0" class="divide-y divide-gray-200 bg-white">
											<tr
												v-for="(activity, index) in activityStore.activities"
												:key="activity.id"
												class="cursor-pointer hover:bg-gray-100"
												:class="index % 2 === 0 ? 'border-gray-300' : 'border-gray-200'"
												:title="
													router.resolve({
														name: 'activity.details',
														params: {
															activity_type: route.params.activity_type,
															activity_id: activity.id,
														},
													}).fullPath
												"
												@click="
													router.push({
														name: 'activity.details',
														params: {
															activity_type: route.params.activity_type,
															activity_id: activity.id,
														},
													})
												"
											>
												<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
													{{ activity.id }}
													<dl class="font-normal lg:hidden">
														<dt class="sr-only">Description</dt>
														<dd class="mt-1 truncate text-gray-700">{{ activity?.description ?? '' }}</dd>
														<dt class="sr-only sm:hidden">Type</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ activity.template?.name }}</dd>
														<dt class="sr-only sm:hidden">Visibility</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ ActivityVisibilityLabel[activity.visibility] }}</dd>
														<dt class="sr-only sm:hidden">Last updated by</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ activity.updater.first_name }} {{ activity.updater.last_name }}</dd>
														<dt class="sr-only sm:hidden">Created by</dt>
														<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ activity.creator.first_name }} {{ activity.creator.last_name }}</dd>
													</dl>
												</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ activity?.description ?? '' }}</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ activity.template?.name }}</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ ActivityVisibilityLabel[activity.visibility] }}</td>
												<td class="px-3 py-4 text-sm text-gray-500">
													<span v-if="activity.status == ActivityStatus.CREATING" class="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">{{ ActivityStatusLabel[activity.status] }}</span>
													<span v-else-if="activity.status == ActivityStatus.OPEN" class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{{ ActivityStatusLabel[activity.status] }}</span>
													<span v-else-if="activity.status == ActivityStatus.RESOLVED" class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">{{ ActivityStatusLabel[activity.status] }}</span>
													<span v-else-if="activity.status == ActivityStatus.CLOSED" class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">{{ ActivityStatusLabel[activity.status] }}</span>
													<span v-else-if="activity.status == ActivityStatus.ARCHIVED" class="inline-flex rounded-full bg-black px-2 text-xs font-semibold leading-5 text-white">{{ ActivityStatusLabel[activity.status] }}</span>
												</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ activity.updater.first_name }} {{ activity.updater.last_name }}</td>
												<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ activity.creator.first_name }} {{ activity.creator.last_name }}</td>
												<td class="px-3 py-4 text-sm text-gray-500 w-px">
													<div class="flex justify-end items-center">
														<FontAwesomeIcon :icon="faChevronRight" class="shrink-0 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
													</div>
												</td>
											</tr>
										</tbody>
										<tbody v-else class="divide-y divide-gray-200 bg-white">
											<tr>
												<td colspan="8">
													<div class="flex flex-row justify-center items-center my-6 font-semibold">
														<h1>You haven't created any {{ plur(route.params.activity_type.toString(), 2) }} yet!</h1>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-else class="flex flex-row justify-center items-center h-full"><Loading /></div>
					</div>
				</div>
			</div>
			<CreateActivity v-if="createNewActivity" :open="createNewActivity" :type="plur(route.params.activity_type.toString(), 1)" @close="createNewActivity = false" />
		</template>
	</AppLayout>
</template>

<script lang="ts" setup>
	import { computed, onMounted, ref } from 'vue';
	import AppLayout from '@layouts/AppLayout.vue';
	import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue';
	import PageHeader from '@components/PageHeader.vue';
	import { useRoute, useRouter } from 'vue-router';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faChevronDown, faChevronRight, faMagnifyingGlass, faPlus } from '@fortawesome/pro-light-svg-icons';
	import { useActivityStore } from '@modules/activities/store';
	import { ActivityStatus, ActivityStatusLabel, ActivityVisibilityLabel } from '@/types/activity.js';
	import Loading from '@components/Loading.vue';
	import CreateActivity from '@modules/activities/components/CreateActivity.vue';
	import plur from '@utils/usePlural';
	import Button from '@components/Button.vue';

	const router = useRouter();
	const route = useRoute();
	const activityStore = useActivityStore();

	const sortOptions = [
		{ name: 'Most Popular', href: '#', current: false },
		{ name: 'Best Rating', href: '#', current: false },
		{ name: 'Newest', href: '#', current: true },
	];
	const filters = [
		{
			id: 'status',
			name: 'Status',
			options: [
				{ value: 'all', label: 'All status', checked: false },
				{ value: 'open', label: 'Open', checked: false },
				{ value: 'rejected', label: 'Rejected', checked: false },
				{ value: 'pending', label: 'Pending', checked: false },
				{ value: 'hold', label: 'On Hold', checked: false },
				{ value: 'resolved', label: 'Resolved', checked: false },
				{ value: 'closed', label: 'Closed', checked: false },
			],
		},
		{
			id: 'assigned',
			name: 'Assigned to',
			options: [
				{ value: 'user_id:0', label: 'The Earl of Sandwich (me)', checked: false },
				{ value: 'user_id:1', label: 'Steven White', checked: false },
				{ value: 'user_id:2', label: 'Eric Heyde', checked: false },
				{ value: 'user_id:3', label: 'Gavin Belson', checked: false },
			],
		},
		{
			id: 'type',
			name: 'Type',
			options: [
				{ value: 'technical', label: 'Technical', checked: false },
				{ value: 'provisioning', label: 'Provisioning', checked: false },
				{ value: 'accounting', label: 'Accounting', checked: false },
				{ value: 'sales', label: 'Sales', checked: false },
				{ value: 'outage', label: 'Outage', checked: false },
				{ value: 'undetermined', label: 'Undetermined', checked: false },
			],
		},
	];
	const activeFilters = [];

	const open = ref(false);
	const currentSortOption = computed(() => {
		for (const option in sortOptions) {
			if (sortOptions[option].current) {
				return sortOptions[option];
			}
		}
		return { name: 'None' };
	});
	const createNewActivity = ref(false);

	onMounted(() => {
		activityStore.getActivities();
	});
</script>
